import moment from 'moment';

export function isValidPhoneNumber(phonenumber) {
  // Regex to check valid
  // International Phone Numbers
  let regex = /^\+[0-9]{1,3}(?:[\s.-]?[0-9]{1,3}){1,2}[0-9]{4,14}$/;
  return regex.test(phonenumber);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeUnderScoreAndMakeEveryFirstLetterCapital(string) {
  let word = string.replace(/_/g, ' ');
  const arr = word.split(' ');

  for (var i = 0; i < arr.length; i++) {
    arr[i] = capitalizeFirstLetter(arr[i]);
  }

  return arr.join(' ');
}
export function formatDate(date) {
  return moment(date).format('MMMM Do YYYY');
}

export function formatDateWithTime(date) {
  return date ? moment(date).format('MMMM Do  h:mm a') : null;
}

export function isValidUUID(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export function mergeArray(oldArray = [], newArray = []) {
  const arrayDic = {};
  oldArray.forEach((item) => (arrayDic[item.id] = item));
  newArray.forEach((item) => (arrayDic[item.id] = item));
  return Object.values(arrayDic);
}
export const formatConditionalNumber = (num) => {
  return Number.isInteger(num) ? num : num.toFixed(2);
};

export function formatRetailerId(value) {
  let formattedValue = String(value)
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '');

  return `${formattedValue.toLowerCase()}_${new Date().getTime()}`;
}
